import React, { useEffect, useState } from "react"
import { graphql, Link, StaticQuery, useStaticQuery, navigate } from "gatsby"

import * as styles from "../../scss/header.module.scss"

import Logo from "../../images/logo_dark.svg"
// import MenuIcon from "../../images/Icons/icon_menu.svg"
import SearchIcon from "../../images/Icons/search.svg"
import CrossIcon from "../../images/Icons/cross.svg"
import TransitionLink from "gatsby-plugin-transition-link"
import { API_BASE } from "../../spectory-config"
import Search from "../elements/Search"
const axios = require('axios');


const Header = (props) => {

    const [transparent, setTransparent] = useState(props.transparent !== undefined && props.transparent !== false);
    const [searchOpened, setsearchOpened] = useState(false);
    const [mobileNavOpened, setMobileNavOpened] = useState(false);
    const [mobileNavOpenedAnimated, setMobileNavOpenedAnimated] = useState(false);
    const [searchData, setSearchData] = useState(null);

    const menuItems = useStaticQuery(graphql`
    query GetMenu {
        allWpMenuItem(filter: {locations: {eq: PRIMARY}}) {
          edges {
            node {
              url
              children {
                children {
                  id
                }
              }
              target
              label
              order
            }
          }
        }
      }
    `
    );

    const menuItemsRender = menuItems.allWpMenuItem.edges
    // .sort((a,b) => {return a.order < b.order})

    useEffect(() => {

        // subscribe event
        if (props.transparent) {
            window.addEventListener("scroll", handleScroll);
        }

        axios.get(`${API_BASE}/searchContent`).then(res => {

            console.log("search data", res.data);
            setSearchData(res.data);

        }).catch(err => {

        })

        return () => {
            // unsubscribe event
            if (props.transparent) {
                window.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    const handleScroll = (e) => {

        if (window.pageYOffset < 300) {
            setTransparent(true)
        } else if (window.pageYOffset >= 300) {
            setTransparent(false)
        }

    }

    const openSearch = () => {
        setsearchOpened(true);
        console.log('OPENED')
    }

    const closeSearch = () => {
        setsearchOpened(false);
    }

    const toggleSearch = () => {
        setsearchOpened(!searchOpened);
    }

    const toggleMenu = (state = null) => {

        if (state == null) {
            state = !mobileNavOpened;
        }

        if (state === true) {

            setMobileNavOpened(state)

            setTimeout(() => {
                setMobileNavOpenedAnimated(state)
            }, 100)

        } else {

            setTimeout(() => {
                setMobileNavOpened(state)
            }, 100)

            setMobileNavOpenedAnimated(state)

        }



    }



    return (
        <>
            <div className={`${styles.header} ` + (transparent ? `${styles.headerStart}` : null)}>
                <Link to={"/"} className={styles.logoLink}>
                    <Logo className={styles.logo} />
                </Link>
                <nav className={styles.nav}>
                    <ul>
                        {
                            menuItemsRender.map((menuItem, key) => {
                                return <li key={key}>
                                    <Link
                                        to={menuItem.node.url}
                                    >
                                        {menuItem.node.label}
                                    </Link>
                                </li>
                            })
                        }
                    </ul>

                </nav>
                <MenuIcon
                    onClick={() => {
                        toggleMenu()
                    }}
                    open={mobileNavOpened}
                />
                <SearchIcon
                    onClick={() => toggleSearch()}
                    className={`${styles.searchIcon} ${searchOpened ? styles.searchIconOpen : styles.searchIconClosed}`} />
                <CrossIcon
                    onClick={() => toggleSearch()}
                    className={`${styles.searchCloseIcon} ${searchOpened ? styles.searchIconOpen : styles.searchIconClosed}`} />

                {
                    searchOpened ?
                        <div
                            className={styles.searchWrapper}
                        >
                            <Search
                                data={searchData}
                            />

                        </div>

                        : null
                }

                {
                    mobileNavOpened ?
                        <nav className={`${styles.mobileNav} ${mobileNavOpenedAnimated ? styles.open : null}`}>
                            <ul>
                                {
                                    menuItemsRender.map((menuItem, key) => {
                                        return <li key={key}>
                                            <Link
                                                onClick={() => toggleMenu(false)}
                                                to={menuItem.node.url}>
                                                {menuItem.node.label}
                                            </Link>
                                        </li>
                                    })
                                }
                            </ul>

                        </nav>

                        : null
                }
            </div>

        </>
    )
}

const MenuIcon = (props) => {

    return (
        <div
            className={`${styles.mobileNavIcon} ${(props.open ? styles.open : null)}`}
            onClick={props.onClick}
        >
            <div className={styles.line}></div>
            <div className={styles.line}></div>
            <div className={styles.line}></div>
        </div>
    )

}

export default Header
