import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import * as styles from "../../scss/socialmedia.module.scss"

import Facebook from "../../images/Icons/facebook.svg"
import Instagram from "../../images/Icons/instagram.svg"
import LinkedIn from "../../images/Icons/linkedin.svg"



const SocialMedia = (props) => {

    const [transparent, setTransparent] = useState(props.transparent !== undefined);

    useEffect(() => {

        // subscribe event
        if (props.transparent) {
            window.addEventListener("scroll", handleScroll);
        }
        return () => {
            // unsubscribe event
            if (props.transparent) {
                window.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    const handleScroll = (e) => {

        if (window.pageYOffset < 300) {
            setTransparent(true)
        } else if (window.pageYOffset >= 300) {
            setTransparent(false)
        }

    }

    return (
        <div className={`${styles.container} ` + (transparent ? `${styles.light}` : null)}>
            <div>
                <Link to="https://www.linkedin.com/company/schön-architektur/" target={"_blank"} className={styles.link}>
                    <LinkedIn className={styles.icon} />
                </Link>
                <Link to="https://www.instagram.com/schoen_architektur/" target={"_blank"} className={styles.link}>
                    <Instagram className={styles.icon} />
                </Link>
            </div>
        </div>
    )
}

export default SocialMedia
