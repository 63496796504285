import React from "react"
import * as styles from "./button.module.scss"
import Arrow from "../../../images/Icons/button_arrow_right.svg"
import { Link } from "@reach/router"
import { withPrefix } from "gatsby-link"

const Button = (props) => {

    if (props.destination !== undefined) {

        if (props.destination.includes('http')) {

            return <a
                className={`${styles.button} ${styles[props.type]} ${styles[props.buttonState]}`}
                href={props.destination}
                target={props.target || ''}
                style={props.additionalStyle || {}}
            >
                {props.title}

                {props.type === 'line' || props.type === 'tertiary' ?
                    <Arrow className={styles.arrow} />
                    : null}
            </a>

        } else {

            return (
                <Link
                    className={`${styles.button} ${styles[props.type]} ${styles[props.buttonState]}`}
                    to={withPrefix(props.destination)}
                    target={props.target || ''}
                    style={props.additionalStyle || {}}
                >
                    {props.title}

                    {props.type === 'line' || props.type === 'tertiary' ?
                        <Arrow className={styles.arrow} />
                        : null}
                </Link>
            )

        }


    } else if (props.type === 'submit') {
        return (
            <input
                type="submit"
                value={props.title || "Absenden"}
                className={`${styles.button} ${styles.primary} ${styles[props.buttonState]}`}
                style={props.additionalStyle || {}}
            />
        );
    } else {
        return (
            <div
                className={`${styles.button} ${styles[props.type]} ${styles[props.buttonState]}`}
                style={props.additionalStyle || {}}
                onClick={() => {
                    if (props.action !== undefined) {
                        props.action();
                    } else {
                        return;
                    }
                }}
            >
                {props.title}

                {props.type === 'line' || props.type === 'tertiary' ?
                    <Arrow className={styles.arrow} />
                    : null}
            </div>
        )
    }


}

export default Button
