import { Link } from "@reach/router";
import React, { useEffect, useState } from "react"
import { API_BASE } from "../../../spectory-config";
import Button from "../Button";
import parse from 'html-react-parser';

import * as styles from './search.module.scss';
import { withPrefix } from "gatsby-link";

const axios = require('axios');
const Search = (props) => {

    const [searchResult, setSearchResult] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const renderPredictions = () => {

        if (searchResult == null || searchTerm === '') return;

        if (searchResult.length < 1) {
            return <h3>Wir haben leider keine Ergebnisse gefunden</h3>
        }

        return <div className={styles.container}>

            {
                searchResult.map(result => {

                    return <div className={styles.searchResultContainer}><Link to={withPrefix(result.path)}>
                        <h4>{result.title}</h4>
                        {
                            result.matches.map(match => {
                                return <div className={styles.matchContainer}>
                                    {parse(match.replace(/<br\/>/g, ' '))}
                                </div>
                            })
                        }
                        </Link>
                        <Button type="line" destination={result.path} title="Zur Seite" />
                        <div className={styles.line}></div>
                        </div>

                })
            }

        </div>

    }

    const handleChange = ({ target: { value } }) => {

        setSearchTerm(value)
        let results = {};

        props.data.forEach(page => {

            let includesSearch = false;

            if (page.title.includes(value)) {

                if (results[page.id] === undefined) {
                    results[page.id] = page;
                    results[page.id].matches = [];
                }

            };

            if (page.path.includes(value)) {

                if (results[page.id] === undefined) {
                    results[page.id] = page;
                    results[page.id].matches = [];
                }

            };

            if (page.content !== null) {

                page.content.forEach(element => {

                    if (element !== null) {

                        element.forEach(elementValue => {

                            if (elementValue.toLowerCase().includes(value.toLowerCase())) {

                                console.log(elementValue, "INCLUDES", value)

                                if (results[page.id] === undefined) {
                                    results[page.id] = page;
                                    results[page.id].matches = [];
                                }

                                const searchTermReg = new RegExp(value, "gi");

                                let matchIndex = null;

                                let matchReg = elementValue.match(searchTermReg);

                                const match = elementValue.replace(searchTermReg, (match) => {

                                    // Replace with the original text, but upper case

                                    matchIndex = elementValue.indexOf(match);

                                    return "<strong>" + match + "</strong>"

                                });

                                let offset = 50;
                                let start = matchIndex - offset;
                                let end = matchIndex + offset + value.toString().length;

                                let pre = '...';
                                let post = '...';
                                if (start < 0) {
                                    start = 0;
                                    pre = '';
                                }

                                if (end > match.length) {
                                    end = match.length;
                                    post = '';
                                }

                                let matchSubstring = pre+match.substring(start, end)+post;

                                    console.log(matchIndex)

                                results[page.id].matches.push(matchSubstring);

                            }

                        })

                    }

                });

            }

            return includesSearch;

        })

        let result = [];

        Object.keys(results).forEach(resultKey => {

            result.push(results[resultKey]);
        })

        console.log(results)

        setSearchResult(result)

    }



    return (
        <div className={styles.searchBar}>
            <input
                type="text"
                className={styles.searchInput}
                onChange={val => handleChange(val)}
                placeholder="Suche..."
            />
            {renderPredictions()}
        </div>
    )

}
export default Search