import React from "react"
import { Link, withPrefix } from "gatsby"

import * as styles from "../../scss/footer.module.scss"

const Footer = () => {
    return (
        <div className={styles.footer}>
            <div>

                <p>
                    Der Weg zum Erfolg ist meistens eine Baustelle.<br />Lassen Sie uns sprechen!
                </p>

                <h3 style={{ marginTop: 50, marginBottom: 50 }}>SCHÖN Architektur</h3>

                <p>
                    Herr Ralf Schön<br />
                    Elisabethstraße 5<br />
                    99096 Erfurt<br />
                </p>

                <br /><br />

                <p>
                    <strong>T:</strong> +49 361 24022172<br />
                    <strong>F:</strong> +49 361 24025134<br />
                    <a style={{ color: 'var(--green-color)' }} href="mailto:info@schoen-architektur.de">E-Mail schreiben</a>
                </p>

                <nav className={styles.nav}>
                    <div>&copy; by SCHÖN Architektur {new Date().getFullYear()}</div>
                    <Link to={"/datenschutz"}>Datenschutz</Link>
                    <Link to={"/impressum"}>Impressum</Link>
                </nav>
                <a href="https://spectory.at" className="producer" target={'_blank'}>
                    designed by spectory
                    </a>
            </div>

        </div>
    )
}

export default Footer
